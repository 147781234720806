import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  html, body, * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Fira Sans', sans-serif;

    text-decoration: none;
    outline: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    list-style: none;
  }

  body {
    background: #eee;
    color:#222831;

    padding: 50px;

    a {
      color:#00adb5;
      transition: all 0.3s ease-in-out;

      &:hover {
        color:#222831;
      }
    }
  }
`;
