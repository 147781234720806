import styled from "styled-components";

export const Container = styled.div`
  height: calc(100vh - 100px);
  width: calc(100vw - 100px);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`;

export const Image = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 100%;
  object-fit: cover;

  margin-top: -10vw;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 800;

  margin: 25px 0 15px;
`;

export const About = styled.h2`
  font-weight: 400;
  font-size: 18px;
  line-height: 1.7;
  text-align: center;

  max-width: 720px;
  width: 100%;

  span {
    font-weight: 800;
  }
`;

export const Hr = styled.hr`
  width: 15%;
  min-width: 280px;
  max-width: 290px;
  border: 0;
  height: 1px;
  background: #393e46;
  margin: 40px 0;
`;

export const Ul = styled.ul`
  display: flex;
  align-items: center;

  li {
    margin: 0 15px;

    svg {
      height: 30px;
      width: auto;
      transition: all 0.3s ease-in-out;
    }

    a:hover {
      svg {
        margin-top: -10px;
        fill: #222831;
      }
    }
  }
`;
