import GlobalStyles from "./styles/global";
import { Container, Image, Title, About, Hr, Ul } from "./styles/App";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

function App() {
  return (
    <>
      <GlobalStyles />

      <Container>
        <Image src="./img/foto.png" title="Gabi Neves" alt="Gabi Neves" />
        <Title>Olá! Muito prazer! Eu sou Gabi Neves.</Title>
        <About>
          Sou neurocomunicadora e tenho a missão de ajudar pessoas e marcas a se
          posicionarem e se comunicarem. Em breve, contarei um pouco mais do meu
          processo por aqui. Enquanto isso, se quiser me conhecer um pouco mais,
          acompanhe minhas&nbsp;redes:{" "}
        </About>

        <Hr />

        <Ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/gabrielaneves/"
            >
              <FaLinkedin />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/gabisnow/"
              title="Pessoal"
            >
              <FaInstagram />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/bondeverde/"
              title="Bonde Verde"
            >
              <FaInstagram />
            </a>
          </li>
        </Ul>
      </Container>
    </>
  );
}

export default App;
